.App {
  text-align: center;
}

/*Button Styling*/

.delete-button {
  width: 4rem;
  align-self: center;
  font-size: small;
}

.filter-button{
  margin: 1rem;
}

.upload-button {
  margin: 1rem;
}

.submit-button-upload {
  width: 10rem;
  align-self: center;
}

/* Badge Styles */

.badge-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0.25rem;
}

.badge {
  width: 5rem;
  margin: 0.5rem;
}

/* Navigation */

/* Form Styles*/
.upload-form {
  display: flex;
  flex-direction: column;
  align-content: center;
}


.form-label {
  font-weight: bolder;
}

/* Painting Styles */

.card {
  border: none;
}

.painting-container {
  display: flex;
}

.painting-thumbnail {
  width: 19rem;
  height: 30rem;
  margin: 1rem;
}

/*Login & Post-Login Styles*/

.login-greeting {
  color: white;
  font-size: xx-small;
  margin: 0;
}

.display-pic {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.login-container {
  float: right;
  margin: 0.5rem;
  padding: 0;
}

/* Filepond Styling */

.filepond--wrapper{
  align-self: center;
  align-content: center;
  justify-content: center;
  width: 40rem;
}

/* Media Styling */
@media screen and (min-width: 300px) and (max-width: 600px) {
  .painting-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .mr-auto, .mr-auto a {
    float: left;
    width: 7rem;
    text-align: start;
  }
}

@media screen and (min-width: 601px) {
  .painting-container {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .mr-auto, .mr-auto a {
    float: left;
    width: 7rem;
    text-align: start;
  }
}
